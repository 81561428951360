export default {
  "还原": "restore",
  "退出编辑": "Exit editing",
  "完成批改": "Complete the correction",
  "提示": "prompt",
  "暂不支持重新编辑 请谨慎提交": "",
  "确定": "Are you sure",
  "退出编辑后，本次编辑内容将会丢失，确定退出？": "After exiting the edit, the edit will be lost, are you sure to quit?",
  "取消拉黑": "",
  "黑名单还是空的": "The blacklist is still empty",
  "取消": "Cancel",
  "接入": "access",
  "接入成功": "",
  "操作失败": "The operation failed",
  "删除会话": "",
  "系统通知": "System notifications",
  "请输入群名称": "Please enter a group name",
  "搜索": "",
  "刷新列表": "",
  "创建会话": "",
  "清空筛选条件": "Clear the filter",
  "在聊中": "In the chat",
  "请输入群名": "Please enter a group name",
  "刷新成功": "Refresh succeeded",
  "没有找到该用户": "The user was not found",
  "输入userID后 按回车键": "After entering the userID, press Enter",
  "取消禁言": "Remove the silence",
  "禁言": "",
  "请输入禁言时间": "Please enter the time of silence",
  "修改群名片": "Modify the group business card",
  "请输入群名片": "Please enter a group business card",
  "踢出群组": "Kick out of the group",
  "不能设置空的群名片": "You cannot set an empty group card",
  "修改成功": "The modification was successful",
  "详情": "detail",
  "添加群成员": "Add group members",
  "群设置": "Party settings",
  "置顶聊天": "Pin chat",
  "查看更多": "See more",
  "群ID": "Group ID",
  "群头像": "Group avatars",
  "群类型": "Group type",
  "群名称": "Group name",
  "群介绍": "",
  "群公告": "",
  "申请加群方式": "How to apply for group enrollment",
  "自由加入": "Feel free to join",
  "需要验证": "Verification required",
  "禁止加群": "Group addition is prohibited",
  "群消息提示类型": "Group message prompt type",
  "接收消息并提示": "Receive messages and prompts",
  "接收消息但不提示": "Receive messages but do not prompt",
  "屏蔽消息": "",
  "我的群名片": "My group business card",
  "全体禁言": "",
  "转让群组": "Transfer a group",
  "新群主的userID": "The userID of the new group owner",
  "退出群组": "Leave the group",
  "解散群组": "Dismiss the group",
  "取消全体禁言": "Lift the blanket ban",
  "普通群成员不能对全体禁言": "Ordinary group members cannot silence all members",
  "退群成功": "Successful withdrawal",
  "该用户未设置昵称": "The user does not have a nickname set",
  "将该用户加入黑名单": "Blacklist the user",
  "加入黑名单": "Join the blacklist",
  "将该用户移出黑名单": "",
  "移出黑名单": "Remove from the blacklist",
  "聊天功能即将上线": "The chat feature is coming soon",
  "敬请期待": "Stay tuned",
  "查看详细信息": "View details",
  "没有更多了": "",
  "回到最新位置": "Back to the latest location",
  "筛选条件": "Filter criteria",
  "时区": "time zone",
  "剩余课时": "Remaining class hours",
  "班主任姓名": "",
  "重置": "reset",
  "筛选": "sift",
  "姓名、学号、或邮箱": "",
  "学生": "student",
  "中文剩余课时小于8": "Chinese less than 8 lessons remaining",
  "中文剩余课时大于等于8": "Chinese remaining class hours greater than or equal to 8",
  "数学剩余课时小于8": "The remaining math lesson hours are less than 8",
  "数学剩余课时大于等于8": "The remaining math class hours are greater than or equal to 8",
  "请输入学生姓名": "Please enter the student's name",
  "没有找到该学生": "The student was not found",
  "暂无好友": "No friends",
  "群头像地址": "Group avatar address",
  "群简介": "Group profile",
  "加群方式": "Group addition method",
  "自由加群": "Free grouping",
  "群成员列表": "List of group members",
  "请输入群成员 userID": "Please enter the group member userID",
  "立即创建": "Create now",
  "群名片": "Group business card",
  "入群时间": "Group time",
  "禁言至": "",
  "发消息": "Send a message",
  "按Enter发送消息": "Press Enter to send the message",
  "开始直播后可以互动聊天哦！": "After starting the live stream, you can interact and chat!",
  "不能发送空消息哦！": "You can't send empty messages!",
  "礼物列表": "List of gifts",
  "直播中": "On air",
  "暂无画面": "",
  "观看直播": "Watch the live stream",
  "暂停观看": "Pause watching",
  "声音": "sound",
  "不能自动播放": "It cannot be played automatically",
  "标题有趣吸引人气": "The title is interesting and popular",
  "开始直播": "Start streaming",
  "结束直播": "End the stream",
  "麦克风": "Microphone",
  "摄像头关闭": "The camera is off",
  "页面-1": "Page-1",
  "j进度条备份": "jProgress bar backup",
  "编组-9": "Marshalling - 9",
  "编组-11": "Marshalling - 11",
  "形状结合": "Shape bonding",
  "直线-5": "Straight-line -5",
  "摄像头": "Camera",
  "手机扫码观看或复制链接分享给好友": "Scan the code on your phone to watch or copy the link to share with friends",
  "复制链接": "Copy the link",
  "分享直播": "",
  "复制成功": "The copy succeeded",
  "复制失败": "Replication failed",
  "头像": "avatar",
  "小悟空": "",
  "你确认要停止接单吗": "Are you sure you want to stop taking orders?",
  "停止接单": "",
  "会话列表": "A list of sessions",
  "群组列表": "A list of groups",
  "好友列表": "Friends list",
  "黑名单列表": "Blacklist list",
  "群直播": "Group live streaming",
  "退出": "",
  "登录": "login",
  "摄像头未打开": "The camera is not turned on",
  "撤回": "withdraw",
  "删除": "",
  "对IM DEMO的评分和建议": "",
  "已解决": "Resolved",
  "未解决，我还有问题咨询": "Unsolved, I still have questions to consult",
  "为您提供专属服务，24小时在线": "",
  "上课情况": "Attendance at lessons",
  "本次共学习": "This time we will learn together",
  "获得奖励数": "Number of rewards earned",
  "答题率": "Answer rate",
  "查看课节详情": "View lesson details",
  "您可以自行解析自定义消息": "",
  "单击下载": "Click Download",
  "点击查看详情": "Click to view details",
  "处理": "dispose",
  "处理加群申请": "Processing of group applications",
  "处理结果：": "Processing result:",
  "同意": "agree",
  "拒绝": "refuse",
  "附言：": "Postscript:",
  "请输入附言": "Please enter a postscript",
  "取 消": "Elimination",
  "确 定": "OK",
  "单击播放": "Click Play",
  "您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器": "Your browser does not support the format of voice message playback, try changing browsers, recommended: Google Chrome",
  "视频出错，错误原因：": "Video error, error cause:",
  "直播已结束": "",
  "您正在其它终端或者Web实例上开播，请勿重复开播！": "If you are broadcasting on another terminal or web instance, please do not start broadcasting again!",
  "发表情": "Post love",
  "发图片": "Send pictures",
  "发视频": "Send a video",
  "发文件": "Send files",
  "发自定义消息": "",
  "小调查": "Small surveys",
  "语音通话": "Voice calls",
  "视频通话": "Video calls",
  "对IM Web demo的建议和使用感受": "Suggestions and experience of IM Web demos",
  "评分": "",
  "建议": "suggestion",
  "请输入内容": "",
  "按Enter发送消息，Ctrl+Enter换行": "",
  "请选择成员": "Please select a member",
  "消息存储失败": "Message store failed",
  "不能发送空消息": "You cannot send an empty message",
  "正在呼叫...": "Calculating...",
  "通话已取消": "",
  "通话已结束": "The call has ended",
  "已挂断": "Hung up",
  "编辑个人资料": "Edit your profile",
  "头像地址(URL)": "",
  "昵称": "nickname",
  "性别": "gender",
  "男": "man",
  "女": "woman",
  "不显示": "Not displayed",
  "头像应该是 Url 地址": "The avatar should be the URL address",
  "访问官网": "Visit the official website",
  "小程序": "",
  "直播电商解决方案": "Live streaming e-commerce solutions",
  "请输入用户名": "",
  "请输入密码": "Please enter a password",
  "请输入 userID": "Please enter the userID",
  "登录成功": "Login successful",
  "登录失败：": "Login failed:",
  "多人处理": "",
  "等待上一步完成": "",
  "待处理": "Pending",
  "已完成": "",
  "已拒绝": "Rejected",
  "(所有人完成，任务才可完成)": "",
  "1组组长(教务部)": "Group 1 Leader (Academic Affairs Department)",
  "教学管理员(教学部)": "",
  "回复": "Reply",
  "流程已结束": "",
  "基本信息": "Basic information",
  "学生名": "",
  "英文名": "English name",
  "年龄": "age",
  "常驻城市": "Resident city",
  "所在时区": "The time zone in which it is located",
  "中文班主任": "Chinese class teacher",
  "数学辅导老师": "Math tutor",
  "中文课程顾问": "Chinese Course Advisor",
  "数学课程顾问": "Mathematics Course Advisor",
  "查看详情": "",
  "体验课": "Hands-on lessons",
  "回放链接": "Replay link",
  "入学学情": "Admission to school",
  "拼音学习史": "History of Pinyin Learning",
  "孩子中文水平": "The child Chinese level",
  "家庭环境语": "Family environment language",
  "汉字书写预期": "",
  "国内中文学习史": "History of domestic Chinese study",
  "曾用教材": "Used teaching materials",
  "海外中文学习史": "History of overseas Chinese study",
  "曾用教材学到第几册": "I have learned the first few volumes with textbooks",
  "家长教学期望": "Parent teaching expectations",
  "授课风格偏好": "Teaching style preference",
  "家长画像与注意事项": "Parental portraits and precautions",
  "中文程度-补充说明": "Degree of Chinese - additional instructions",
  "上传头像": "Upload an avatar",
  "上传": "",
  "今日": "",
  "本月": "",
  "设置": "",
  "退出登录": "Sign out",
  "已接入网络": "Connected to the network",
  "当前网络不稳定": "The current network is unstable",
  "当前网络不可用": "The current network is not available",
  "新系统通知": "New system notification",
  "线索来源": "Clue source",
  "请输入": "Please enter",
  "线索阶段": "",
  "请选择": "Please select",
  "查询": "Inquire",
  "待分配": "To be assigned",
  "待跟进": "",
  "跟进中": "",
  "已约课": "Scheduled lessons",
  "CC待跟进": "CC to follow",
  "CC跟进中": "CC is following up",
  "发送订单": "Send the order",
  "财务确认到账": "Financial confirmation arrives",
  "已交接": "Handed over",
  "无需发货": "",
  "待确认": "",
  "待发货": "",
  "已发货": "Shipped",
  "已取消": "Canceled",
  "转账": "Transfer",
  "支付宝": "Alipay",
  "微信": "",
  "信用卡": "credit card",
  "银联": "UnionPay",
  "零元": "Zero yuan",
  "其它": "other",
  "审核中": "Under review",
  "已通过": "Passed",
  "已撤销": "Revoked",
  "中文": "Chinese",
  "数学": "mathematics",
  "新生报班": "New students sign up for classes",
  "续费": "Renewal",
  "补升": "Make-up",
  "赠课时": "Complimentary lessons",
  "待支付": "",
  "已支付": "Paid",
  "已支付待确认": "Payment pending confirmation",
  "已退款": "Refunded Received",
  "学生续费": "Student renewal",
  "学生补升": "Students are promoted",
  "定金": "deposit",
  "国际运费": "International shipping costs",
  "学员升级": "Learner upgrades",
  "活动寄送": "Event delivery",
  "序号": "serial number",
  "线索名称": "The clue name",
  "手机号": "Mobile phone number",
  "学员": "",
  "推荐人": "Referrer",
  "分销大使": "Distribution Ambassador",
  "邮箱": "",
  "线索类型": "The type of lead",
  "阶段": "stage",
  "科目": "",
  "未试听原因": "The reason for not auditioning",
  "创建时间": "Creation time",
  "组织数据": "",
  "本周": "Week",
  "本日": "",
  "订单状态": "Order status",
  "转介绍线索": "Referral leads",
  "我的组织": "My organization",
  "组织(部门):": "",
  "时间范围": "Time range",
  "同级组织": "Sibling organizations",
  "成员数据": "Member data",
  "员工姓名:": "Employee Name:",
  "员工邮箱:": "",
  "姓名": "",
  "续费订单量": "Number of renewal orders",
  "续费金额/元": "Renewal amount/RMB",
  "补升订单量": "Replenish the order volume",
  "补升金额/元": "",
  "组织": "organization",
  "组织负责人": "Head of organization",
  "当前学员": "Current learners",
  "去排课": "Go to class scheduling",
  "课节列表": "A list of lessons",
  "课节日历": "Lesson calendar",
  "班级名称": "Class name",
  "班级类型": "Class type",
  "授课老师": "Instructor",
  "课节状态": "Section status",
  "学生课节状态": "Student section status",
  "上课日期(学生)": "",
  "上课日期(北京)": "Class Date (Beijing)",
  "重点": "",
  "回放": "playback",
  "作业": "homework",
  "报告": "",
  "教学重点": "Teaching focus",
  "班课": "Class lessons",
  "私教课": "Private lessons",
  "课节名称": "Section name",
  "所属班级": "",
  "课节类型": "Lesson type",
  "上课时间(班级时区)": "Class time (class time zone)",
  "上课时间(学生时区)": "",
  "操作": "operate",
  "待开课": "Classes to start",
  "旷课": "truancy",
  "预排课": "Pre-scheduled lessons",
  "回放课节": "Replay the lesson section",
  "课后作业": "Homework",
  "学习报告": "Learning reports",
  "题号": "Title number",
  "正确答案": "Correct answer",
  "学员答案": "Learner answers",
  "最终答案": "The final answer",
  "课节资源": "",
  "名称": "name",
  "类型": "",
  "查看": "View",
  "暂无数据": "No data yet",
  "复习": "review",
  "预习": "Preview",
  "姓名或学号": "Name or student number",
  "申请人": "applicant",
  "审批状态": "Approval status",
  "变更类型": "Change type",
  "审批详情": "Approval details",
  "待审核": "To be reviewed",
  "关联学生": "Link students",
  "申请详情": "Application details",
  "审批结果": "Approve the results",
  "审核通过": "Approved",
  "不通过": "",
  "撤销": "quash",
  "撤销审批": "",
  "确定撤销": "Confirm revocation",
  "关闭": "Shut down",
  "您确定": "Are you sure",
  "确定撤销后，审核人员将不再会对该记录进行审批": "When the revocation is confirmed, the record will no longer be approved by the auditor",
  "提交": "submit",
  "请填写": "Please fill in",
  "实际课时转移": "Actual lesson time transfer",
  "课时转移成功": "Lesson transfer successful",
  "课时转移": "Lesson time transfer",
  "课时返还": "Lesson hours are returned",
  "课时赠送": "Complimentary lessons",
  "已撤消": "Revoked",
  "我提交": "I submit",
  "待审批": "",
  "已审批": "Approved",
  "变更学生": "Change students",
  "课时变更类型": "",
  "申请变更日期": "Date of request change",
  "审核人": "Approver",
  "课时审批": "Lesson approval",
  "学员状态审批": "",
  "订单审批": "Order approval",
  "提交人": "Author",
  "订单号": "Order number",
  "订单类型": "Order type",
  "订单审批类型": "The order approval type",
  "审核状态": "Audit status",
  "订单已取消": "The order has been canceled",
  "商品详情": "Product details",
  "商品名称": "Product name",
  "折扣": "discount",
  "数量": "quantity",
  "超额赠课": "",
  "订单折扣": "Order discounts",
  "超额赠额度": "Overage credit",
  "订单编号": "Order number",
  "提交日期": "Date of submission",
  "冻结课程": "Freeze the course",
  "延期开课": "",
  "期望变更状态": "Expect to change status",
  "延期/冻结时间": "",
  "申请日期": "Date of application",
  "加载中": "Loading",
  "已经到底了...": "Already to the end...",
  "查看全部": "See all",
  "数据简报": "Data briefs",
  "实现课消": "Achieve class elimination",
  "续补达成": "Continuation achieved",
  "线索达成": "Clues are reached",
  "推荐线索": "Recommend leads",
  "累计退费学员": "Cumulative refund for students",
  "本月不续费学员": "Students will not be renewed this month",
  "任务及时率": "Task timeliness",
  "服务分": "Service points",
  "服务人数": "Number of people served",
  "暂未上传数据简报": "No data briefs have been uploaded",
  "学员分层": "Learner stratification",
  "续费分类": "Renewal classification",
  "快捷入口": "Quick entry",
  "学员作业": "Trainee assignments",
  "输入学生姓名或学号": "Enter the student's name or student number",
  "作业状态": "",
  "上课时间": "Class time",
  "未发布": "Not published",
  "未完成": "to be continued",
  "部分完成": "Partially completed",
  "学生姓名": "Student name",
  "课节时间": "Lesson time",
  "提交时间": "Submission time",
  "正确率": "Accuracy rate",
  "课时信息": "Lesson information",
  "申请变更": "Request a change",
  "剩余总课时": "Total lesson hours remaining",
  "已扣课时数": "Hours have been deducted",
  "伴学奖赠课": "Companion scholarship for lessons",
  "购买课时": "Purchase lessons",
  "推荐赠课": "Recommended free lessons",
  "手动变更课时": "Change lesson hours manually",
  "分销赠课": "Distribution of free lessons",
  "购买赠课": "Purchase free lessons",
  "社交平台分享赠课": "Social platforms share free lessons",
  "入学分享赠课": "Admission sharing and free lessons",
  "小班课专属赠课": "Exclusive free lessons for small classes",
  "剩余额度 = 总额度 - 已获分享额度": "Remaining Credit = Total - Shared credit",
  "分享赠课额度（自动计算）": "Share lesson credit (automatically calculated)",
  "分享赠课额度（调整）": "",
  "其他": "other",
  "其他信息": "Additional Information",
  "创建人": "Created by",
  "学生所有人": "Student Owner",
  "当前学情": "",
  "中文-课程进度": "Chinese - Course progress",
  "主修课班型": "Major class type",
  "周消耗课时": "Weekly consumption of class hours",
  "主修课班级名称": "The name of the major class",
  "上课时长": "Class length",
  "当前级别": "Current level",
  "最近完成的主修课": "Recently completed major",
  "中文-教学期望": "Chinese - Pedagogical expectations",
  "听说": "",
  "读": "read",
  "写": "write",
  "课程进度": "Course progress",
  "作业难度": "Difficulty of the assignment",
  "作业量": "The amount of work",
  "中文-其他学情": "Chinese - Other academic situations",
  "授课语言": "Language of instruction",
  "教学互动": "Teaching interaction",
  "作业完成度": "Job completion",
  "行为习惯": "Behavioral habits",
  "中文-排课信息": "Chinese - Scheduling information",
  "入学时间（北京时间）": "Enrollment Time (Beijing Time)",
  "无": "not",
  "首课时间（北京时间）": "First Lesson Time (Beijing Time)",
  "补升权益": "Top-up equity",
  "阅读课（北京时间）": "Reading Class (Beijing Time)",
  "数学-课程进度": "Mathematics - Course Progress",
  "数学-教学期望": "Mathematics - pedagogical expectations",
  "数与代数": "Numbers and algebra",
  "几何与测量": "",
  "推理与策略": "Inference and strategy",
  "应用与实战": "Application and practice",
  "统计与概率": "",
  "数学-其他学情": "Mathematics - Other Studies",
  "数学-排课信息": "Math - Scheduling information",
  "学号": "Student number",
  "生日": "birthday",
  "常驻国家/地区": "",
  "入学年龄": "Entry age",
  "城市": "city",
  "当前年龄": "",
  "学生币种": "Student currency",
  "母语": "mother tongue",
  "中文普通话": "",
  "英语": "English",
  "日语": "Japanese",
  "韩语": "Korean",
  "法语": "French",
  "德语": "German",
  "印尼语": "",
  "手机": "",
  "推广大使": "Ambassadors",
  "接收": "reception",
  "公众号提醒": "Official account reminder",
  "家长喜欢的沟通方式": "Parents like to communicate",
  "电话": "Phone",
  "短信": "Sms",
  "邮件": "mail",
  "各种方式都联系不上": "",
  "服务信息": "Service Information",
  "数学助教": "Math Teaching Assistant",
  "账号信息": "Account Information",
  "Classin上课账号": "Classin class account",
  "Classin同步UUID": "Classin synchronizes the UUID",
  "学生状态(中文)": "Student Status (Chinese)",
  "学生状态": "Student status",
  "学生状态(数学)": "Student Status (Mathematics)",
  "学生可用时间": "Available time for students",
  "我的时区": "",
  "每周一": "Every Monday",
  "请选择我的时区": "Please select my time zone",
  "每周二": "Every Tuesday",
  "每周三": "Every Wednesday",
  "每周四": "Every Thursday",
  "每周五": "Every Friday",
  "每周六": "Every Saturday",
  "每周天": "Every Sunday",
  "请选择学生时区": "Please select the student time zone",
  "邮寄地址": "Mailing address",
  "收件人姓名": "",
  "收件人电话": "Recipient phone",
  "收货国家/地区": "Country of delivery",
  "州/省": "State/Province",
  "街道": "street",
  "邮政编码": "Zip code",
  "保存编辑": "Save the edits",
  "确认订单寄送信息": "",
  "订单": "orders",
  "创建于": "Created in",
  "客户": "client",
  "收货人": "consignee",
  "收货人电话": "",
  "学生画像": "Student portrait",
  "学习风格": "Learning style",
  "视觉型": "Visual",
  "听觉型": "Auditory type",
  "体验型": "Experiential",
  "兴趣爱好课外班": "Hobbies: Extracurricular classes",
  "绘画、舞蹈、声乐、表演等艺术类": "Painting, dance, vocal music, performance and other arts",
  "钢琴、小提起等乐器类": "Piano, small lift and other musical instruments",
  "编程、乐高、Stem等科技编程类": "Programming, Lego, Stem and other technological programming classes",
  "数学、英文等基础学科类": "Mathematics, English and other basic subjects",
  "游泳、网球、篮球等运动类": "Swimming, tennis, basketball and other sports",
  "其他Online课程": "Other Online courses",
  "绘画Online": "Draw Online",
  "乐器陪练": "Instrumental sparring",
  "数学Online": "Math Online",
  "英文Online": "English Online",
  "编程Online": "Programming Online",
  "学生画像补充": "Student portrait supplement",
  "家长画像": "Parental portraits",
  "学习负责人": "Head of Learning",
  "妈妈": "Mom",
  "爸爸": "Father",
  "爸爸妈妈都负责": "Mom and Dad are in charge",
  "职业状态": "Professional status",
  "家庭为主": "Family based",
  "职场精英": "",
  "不确定": "Up in the air",
  "互动活跃度": "Interaction activity",
  "热情互动": "Enthusiastic interaction",
  "常规沟通": "Regular communication",
  "偶尔回复": "",
  "基本不回复": "Basically do not reply",
  "家长影响力": "",
  "明星级博主/自媒体，影响力卓越": "Star-level blogger/self-media, excellent influence",
  "群主/KOL，影响力较大": "",
  "推荐朋友较多，有一定影响力": "Recommend more friends and have a certain influence",
  "常规": "",
  "沟通方式偏好": "Communication preferences",
  "开启": "Open",
  "沟通时间偏好": "Communication time preference",
  "家长画像补充": "Parental portrait supplement",
  "希望加强练习": "Hope to strengthen the practice",
  "保持不变": "Remain unchanged",
  "希望适当减少": "Hope to reduce appropriately",
  "希望快一点": "Hope it's faster",
  "希望慢一点": "Hopefully slower",
  "希望难一点": "",
  "希望简单一点": "Wish it was simpler",
  "希望少一点": "Hopefully a little less",
  "希望多一点": "Wish for a little more",
  "其他/特殊需求": "Other/Special Needs",
  "俄语": "Russian",
  "粤语": "Cantonese",
  "泰语": "Thai",
  "西班牙语": "",
  "新加坡英语": "Singaporean English",
  "阿拉伯语": "Arabic",
  "高，乐于表达": "High, willing to express",
  "中，需要老师引导": ", need to be guided by the teacher",
  "低于50%，需关注": "Below 50%, need attention",
  "低，基本不参与互动": "Low, basically does not participate in interaction",
  "超80%完成，优秀": "Over 80% complete, excellent",
  "80%～50%完成，良好": "",
  "准时出席": "Be on time",
  "偶尔缺席/迟到": "Occasional absence/tardiness",
  "高频缺席/迟到": "High-frequency absences/lateness",
  "入学时间(北京时间)": "Enrollment Time (Beijing Time)",
  "首课时间(北京时间)": "First Lesson Time (Beijing Time)",
  "阅读A班-北京时间周六08:00": "Read Class A - Saturday at 08:00 Beijing time",
  "阅读B班-北京时间周六16:00": "Read Class B - Saturday at 16:00 Beijing time",
  "入学日期(北京时间)": "Date of Enrollment (Beijing Time)",
  "中文-入学学情": "Chinese - Admission to school",
  "家庭语言环境": "Home locale",
  "学生画像/爱好/兴趣点": "Student portraits/hobbies/points of interest",
  "中文-体验课信息": "Chinese - Experience lesson information",
  "中文-学习计划": "Chinese - Study Plan",
  "科目类型": "Account type",
  "级别（I）": "Level (I)",
  "级别（II）": "",
  "级别（III）": "Level (III)",
  "班型": "Class type",
  "上课频率（每周）": "Frequency of classes (weekly)",
  "当前授课老师": "Current instructor",
  "数学-入学学情": "Mathematics - Admission Profile",
  "孩子数学水平": "The child's level of mathematics",
  "优秀": "outstanding",
  "良好": "good",
  "一般": "",
  "参与有数学辅导班": "Participation in math tutoring classes",
  "有": "Yes",
  "参与有其他兴趣班": "Participate in classes with other interests",
  "数学-体验课信息": "Math - Experience Class Information",
  "数学-学习计划": "Mathematics - Study Plan",
  "更改首课时间": "Change the first lesson time",
  "学情信息": "Academic information",
  "入学日期（北京时间）": "Date of Enrollment (Beijing Time)",
  "回放连接": "Playback connection",
  "入学教学期望": "Admission teaching expectations",
  "测试级别": "Test level",
  "分数": "fraction",
  "入学在读年级": "Grade of enrollment",
  "数学入学年龄": "Math entry age",
  "体验课名称": "The name of the lesson and the name of the lesson",
  "体验课老师": "Experience class teacher",
  "体验课回放": "",
  "课后点评": "After-class reviews",
  "的线索": "clues",
  "新建线索": "Create a new lead",
  "拓科": "Topco",
  "多娃": "Dova",
  "去约课": "Go to an appointment",
  "复制约课链接": "Copy the appointment link",
  "约课": "Make an appointment",
  "发送至群": "Send to the group",
  "正常": "normal",
  "公海": "high seas",
  "回收站": "recycle bin",
  "线索待分配": "Leads to be assigned",
  "线索待跟进": "Clues to follow",
  "线索跟进中": "Clues are following up",
  "线索已约课": "",
  "CC发送订单": "CC sends the order",
  "CC财务确认到账": "CC financial recognition arrived",
  "普通线索": "",
  "学员拓科": "Trainee Topco",
  "一家多娃": "A family of dova",
  "外部线索": "External leads",
  "中文班主任自建单": "Chinese the class teacher self-created the order",
  "Math辅导老师自建单": "Math tutors create their own orders",
  "新西兰转账": "New Zealand transfers",
  "跨国转账": "Cross-border transfers",
  "国内转账": "Domestic transfers",
  "现金": "cash",
  "银行卡": "",
  "进线类型": "Incoming line type",
  "来源": "source",
  "销售负责人": "Head of Sales",
  "约课负责人": "The person in charge of the appointment",
  "所有人": "All of them",
  "的订单": "",
  "寄送状态": "Shipping status",
  "输入订单号": "Enter the order number",
  "新建订单": "Create a new order",
  "取消订单": "Cancel the order",
  "下载invoice": "Download invoice",
  "申请财务到账": "Apply for financial credit",
  "商品": "",
  "寄送": "Send",
  "收货地址": "Shipping address",
  "物流公司": "Logistics companies",
  "物流编号": "",
  "支付信息": "Payment Information",
  "财务审核人": "",
  "审核时间": "Review time",
  "财务确认状态": "Financial recognition status",
  "支付方式": "Payment Methods",
  "付款时间": "Payment time",
  "付款账户持卡人": "Payment account cardholder",
  "支付渠道流水": "Payment channel flow",
  "财务周期": "",
  "财务审核备注": "Financial audit notes",
  "付款凭证": "Proof of payment",
  "转账汇率换算截图": "Screenshot of transfer exchange rate conversion",
  "退款信息": "Refund Information",
  "退款商品": "Refund items",
  "退款数量": "",
  "退款总金额": "The total amount of the refund",
  "退款状态": "Refund status",
  "退款原因": "Reason for refund",
  "退款完成时间": "Refund completion time",
  "退款截图": "Screenshot of the refund",
  "订单信息": "Order Information",
  "FEDEX联邦": "FEDEX Federation",
  "顺丰": "SF",
  "专线": "Line",
  "中通": "ZTO",
  "支付失败": "Payment failed",
  "商品标签": "Product labels",
  "订单金额": "Order amount",
  "个人信息": "Personal Information",
  "数学班主任": "Math homeroom teacher",
  "微信OPENID": "WECHAT OPENID",
  "ClassIn同步UUID": "ClassIn synchronizes the UUID",
  "学生状态-中文": "Student Status - Chinese",
  "学生状态-数学": "Student Status - Mathematics",
  "发货地址": "Ship-from address",
  "修改手机号": "Change your phone number",
  "保存": "Save",
  "原手机号": "Original mobile phone number",
  "新手机号": "New phone number",
  "修改邮箱": "Modify the mailbox",
  "原邮箱": "Original email address",
  "新邮箱": "",
  "班级": "Class",
  "下载": "Download",
  "生成时间": "Build time",
  "家长微信昵称": "Parents' WeChat nickname",
  "用户影响力": "User impact",
  "沟通时间偏好(学生时间)": "Communication Time Preference (Student Time)",
  "中文分享链接": "Chinese share the link",
  "数学分享链接": "",
  "分享链接": "",
  "群成员": "Group members",
  "搜索聊天记录": "Search chat history",
  "刷新": "flushed",
  "(系统修复)": "(System Repair)",
  "操作员姓名": "Operator name",
  "类别": "category",
  "说明": "illustrate",
  "日期": "date",
  "备注": "",
  "来源信息": "Source information",
  "科 目": "Section",
  "变更方式": "Change the method",
  "增加": "increase",
  "减少": "",
  "变更课时数": "Change the number of lessons",
  "请输入变更课时数": "",
  "上传截图": "",
  "上传图片": "Upload an image",
  "备 注": "Remarks",
  "请输入备注": "Please enter a comment",
  "请选择科目": "Please select an account",
  "请选择课时变更类型": "Please select the lesson change type",
  "请选择变更方式": "",
  "成员管理": "Member management",
  "请输入姓名": "Please enter your name",
  "请输入邮箱": "Please enter your email address",
  "请输入工号": "Please enter the work number",
  "职务": "",
  "工作性质": "Nature of work",
  "账号状态": "Account status",
  "他的学生": "His students",
  "工号": "Work number",
  "入职日期": "Date of entry",
  "全职": "Full-time",
  "兼职": "",
  "禁用": "disable",
  "班主任": "Teacher",
  "班主任主管": "Head of class teacher",
  "班主任组长": "",
  "教务": "",
  "教务主管": "Head of Academic Affairs",
  "查看学生信息": "View student information",
  "增长活动": "",
  "分享海报": "Share the poster",
  "福利官": "Welfare Officer",
  "(大使助理)": "(Assistant Ambassador)",
  "剩余课时不足，请联系学生续课续费": "If the remaining class hours are insufficient, please contact the student to renew the course",
  "资料": "information",
  "学情": "Academic affection",
  "阶段学习报告": "Stage learning report",
  "阶段结业证书": "Certificate of Stage Completion",
  "跟进记录": "Follow up on records",
  "聊天记录": "Chat history",
  "跟进类型": "Follow-up type",
  "已完成任务": "The task has been completed",
  "超过30秒建议添加跟进记录": "After 30 seconds, it is recommended to add a follow-up record",
  "顾问": "consultant",
  "描述": "description",
  "课表": "Schedule",
  "课表详情": "Details of the class schedule",
  "今天": "Today",
  "节课": "Lessons",
  "明天": "Tomorrow",
  "近7天": "Last 7 days",
  "聊天": "chat",
  "待办": "To do",
  "新建任务": "Create a new task",
  "确认首课时间": "Confirm the first lesson time",
  "稍后": "Later",
  "请先完成排课后，再重新确认首课时间": "Please complete the schedule before reconfirming the first lesson",
  "分享赠课审批": "Share class grant approval",
  "学生分享赠课链接确认": "Students share the lesson link to confirm",
  "任务详情": "Task details",
  "课时": "Class",
  "余小静": "Yu Xiaojing",
  "学生信息": "Student information",
  "当前班型": "Current shift type",
  "当前科目": "The current account",
  "当前科目类型": "The current account type",
  "流失前班主任": "Former head teacher attribution",
  "流失前课程顾问": "Pre-attrition course consultant",
  "流失信息": "Churn information",
  "流失类型": "Churn type",
  "流失主要原因": "The main cause of churn",
  "流失主因II级": "The main cause of loss is grade II",
  "流失主因III级": "The main cause of loss is grade III",
  "其他流失原因": "Other reasons for churn",
  "流失去向": "Flow loses direction",
  "不续费原因分类": "Classification of reasons for non-renewal",
  "不续费决定日期": "No renewal decision date",
  "退费原因分类": "Classification of refund reasons",
  "退费决定日期": "The refund decision date",
  "退费状态": "Refund status",
  "已经消耗课时数": "The number of hours of lessons has been consumed",
  "试学期退费": "Trial semester refund",
  "入学标准": "Admission Criteria",
  "挽单信息": "Order information",
  "班主任沟通记录": "Class teacher communication records",
  "顾问沟通记录": "Consultant communication records",
  "教学沟通记录": "Records of teaching and communication",
  "其他备注原因": "",
  "挽单沟通描述": "Description of the communication of the order",
  "挽单沟通记录": "",
  "沟通记录": "Communication records",
  "关联订单": "Link orders",
  "退费金额": "Amount of refund",
  "退费币种": "The currency of the refund",
  "删除学员流失记录": "Delete learner churn records",
  "确认": "Confirm",
  "学 号": "Student number",
  "学生ID": "Student ID",
  "当前级别I": "Current Level I",
  "流失主因II": "The main cause of attrition is II",
  "流失主因III": "The main cause of loss is III",
  "全部退": "Return them all",
  "部分退": "Partial withdrawal",
  "已耗课时数": "Number of class hours consumed",
  "本次退费是否属于试学期退费情况，请选择": "Please select whether this refund is a trial semester refund",
  "是": "be",
  "否": "not",
  "退费学生在入学时是否符合该学科入学标准，请选择": "Please select whether the refunded student meets the admission criteria for the subject at the time of admission",
  "符合": "accord with",
  "不符合": "Non-conformity",
  "其他原因备注": "Other reason remarks",
  "关联订单号": "Associate the order number",
  "请输入订单号，以逗号间隔": "Please enter order numbers, separated by commas",
  "请输入学生ID": "Please enter the student ID",
  "请选择学号": "Please select a student number",
  "请选择班型": "Please select a class type",
  "请选择科目类型": "Please select an account type",
  "请选择流失主要原因": "Please select the main reason for churn",
  "请选择流失主因II": "Please select the main cause of churn II",
  "请选择流失主因III": "Please select Cause III of Churn",
  "请选择其他流失原因": "Please select a different reason for churn",
  "请选择流失去向": "Please select Flow Loss Direction",
  "请选择流失类型": "Please select the churn type",
  "请选择当前级别": "Please select the current level",
  "请选择授课老师": "",
  "请选择流失前课程顾问": "Please select a pre-churn course advisor",
  "请选择流失前班主任": "Please select the former class teacher",
  "请输入班主任沟通记录": "Please enter the communication record of the class teacher",
  "请选择决定日期": "Please select a decision date",
  "请选择试用期退费": "Please select a trial period for a refund",
  "请选择入学标准": "Please select admission criteria",
  "请选择退费状态": "Please select the refund status",
  "请输入订单号": "Please enter the order number",
  "请输入已消耗课时数": "Please enter the number of hours consumed",
  "请选择退款状态": "Please select the refund status",
  "流失状态": "Churn status",
  "组织(部门)": "Organization (Department)",
  "重设": "Reset",
  "新建": "newly built",
  "编辑": "edit",
  "完成": "finish",
  "确定完成": "",
  "未处理": "",
  "发起审批": "Initiate an approval",
  "请选择审批类型": "Please select an approval type",
  "请选择学生": "Please select a student",
  "请选择最迟解决时间（北京时间）": "Please select the latest resolution time (Beijing time)",
  "操作提示": "How-to prompts",
  "确定提交": "Confirm the submission",
  "暂不": "Not yet",
  "请确认学生": "Please confirm the student",
  "更多信息": "More information",
  "离职": "",
  "请选择交接类型": "Please select a handover type",
  "请选择离职时间（北京时间）": "Please select a departure time (Beijing time)",
  "班级交接周期": "Class handover cycle",
  "请选择班级交接周期": "Please select the class handover cycle",
  "紧急交接": "",
  "常规交接": "Regular handover",
  "请选择离职对外话术": "",
  "学生升入更高级别（大级别），申请安排其他老师": "",
  "老师转岗": "Teacher transfer",
  "老师进修，暂停教学工作": "Teachers continue their training and suspend teaching",
  "其他（请补充在下列真实原因中）": "",
  "请选择离职真实原因": "",
  "其他真实原因": "",
  "请输入离职真实原因": "Please enter the true reason for leaving",
  "我的任务-待办": "",
  "班主任离职班级交接学生": "The class teacher leaves the class and the class hands over the student",
  "交接学生": "",
  "交接类型": "Handover type",
  "交接班主任": "",
  "请选择交接班主任": "Please select the shift teacher",
  "选择交接时间段": "",
  "分配学生": "Assign students",
  "确定删除该行吗?": "",
  "新增": "",
  "新班主任": "New class teacher",
  "选择科目": "Select an account",
  "分配学生名单": "Assign a list of students",
  "首通处理时间": "",
  "离职交接": "Separation handover",
  "普通交接": "Normal handover",
  "请选择日期(北京时间)!": "Please select a date (Beijing time)!",
  "请选择问题": "",
  "请输入科目": "Please enter a subject",
  "请输入学生学号": "Please enter the student student number",
  "请选择时间（北京时间）": "Please select a time (Beijing time)",
  "审批原因": "",
  "请详细描述补充说明": "Please describe the additional description in detail",
  "新建大娃": "New big baby",
  "请选择进线类型": "Please select the incoming line type",
  "请选择线索来源": "Please select a lead source",
  "请选择分销大使": "Please select a Distribution Ambassador",
  "请输入线索姓名": "Please enter the name of the lead",
  "家长沟通语言": "Language of communication for parents",
  "补充资料": "Additional information",
  "未知": "Unknown",
  "请输入数字": "Please enter a number",
  "微信老师": "WeChat teacher",
  "请选择老师": "",
  "继续添加": "Continue adding",
  "上一步": "",
  "下一步": "Next",
  "中文(粤语)": "",
  "社群转介绍活动": "Community referral activities",
  "分销渠道": "Distribution channels",
  "取消新建": "",
  "继续填写": "",
  "发送邮件": "",
  "插入邮件模板": "Insert a message template",
  "洋洋": "Yangyang",
  "确认发送": "",
  "搜索模板名称": "Search for the template name",
  "级别": "",
  "插入模板": "Insert a template",
  "模板名称": "Template name",
  "插入此模板将覆盖当前邮件": "",
  "插入": "insert",
  "已办": "Done",
  "抄送我": "CC me",
  "我创建": "I create",
  "我的待办": "My to-do",
  "任务说明": "Task description",
  "完成建群后，该条任务自动变更成已完成": "After the group is completed, the task is automatically changed to Completed",
  "跟家长打招呼做自我介绍，建立初步联系": "",
  "请务必在学生首课结束后30天以内确认补升权益": "",
  "b. 查看试听课回放形成初步客户画像": "",
  "c. 了解报名课包信息": "",
  "a. 每次联系沟通情况，无论是否有效沟通，均须及时填写跟进记录，说明沟通情况": "a. Each contact communication, whether effective communication or not, must be filled in in a timely manner to explain the communication",
  "c. 完成排课等待处理事项": "",
  "请务必在72小时内完成排课": "",
  "请务必在学生首课开始前完成新生寄送订单确认": "",
  "与家长确认邮寄信息，并确认系统信息填写是否正确": "Confirm the mailing information with the parents and confirm that the system information is filled in correctly",
  "请务必在首课结束后48小时内完成首课回访": "",
  "请务必在48小时内完成任务": "Be sure to complete the task within 48 hours",
  "c. 是否有疑问，有的话做对应异议处理和解答": "c. Whether there are any questions, if so, we will handle and answer the corresponding objections",
  "d. 提醒补升机会，建议家长多关注和了解课程做考虑": "",
  "a. 学情沟通，反馈和总结学情": "a. Communication, feedback and summary of academic information",
  "b. 提醒家长补升机会和意向": "b. Remind parents of opportunities and intentions",
  "c. 挖掘家长不参加的原因并做异议处理": "c. Find out the reasons for parents' non-participation and handle objections",
  "请务必在每月25日之前完成任务": "Be sure to complete the task by the 25th of each month",
  "b. 针对缺点做后续的学习规划调整，需要家长如何配合": "b. Parents need to cooperate with the follow-up learning plan adjustment for the shortcomings",
  "请务必先确认前班主任是否完成交接备注并通知到家长，若未填写，请及时联系前班主任，并在跟进记录中做好备注": "",
  "交接入群后，请及时与家长打招呼，完成自我介绍与交接情况沟通": "",
  "请务必在72小时内完成任务": "Be sure to complete the task within 72 hours",
  "批量入群": "Join groups in batches",
  "输入任务名称": "Enter a task name",
  "任务状态": "Task status",
  "搜索学生姓名或学号": "Search for a student's name or student ID",
  "选择离职交接或普通交接": "Select Exit Handover or Normal Handoff",
  "交接学员详情": "Handover details of the trainees",
  "任务": "",
  "离职原因": "Reason for leaving",
  "离职时间": "",
  "处理人": "Handlers",
  "截止时间": "Deadline",
  "处理状态": "Processing status",
  "处理时间": "",
  "任务类型": "Task type",
  "阶段结课课节": "",
  "课程类型": "Course type",
  "交班合规": "Shift compliance",
  "请假原因": "Reason for taking leave",
  "交接班级": "Handover of classes",
  "任务描述": "Task description",
  "收件人（学生）": "Recipient (Student)",
  "订单创建人": "",
  "缺课学生": "Students who are absent from school",
  "缺课课节": "Missing class sessions",
  "缺课日期": "Missed school dates",
  "任务截止时间": "Task deadline",
  "相关课节": "",
  "提交人角色": "Role of the author",
  "课程顾问": "Course Consultant",
  "截至时间": "Deadline",
  "处理结果": "Process the results",
  "首课时间": "",
  "课节": "Lessons",
  "前班主任": "Former head teacher",
  "开课时间": "",
  "状态": "state",
  "任务名称": "Task name",
  "任务时段": "",
  "执行人": "Executor",
  "完成任务": "",
  "冻结/延期结束时间": "",
  "补充说明": "Additional instructions",
  "待办任务": "To-do tasks",
  "已办任务": "Tasks done",
  "抄送我任务": "",
  "我创建任务": "I create the task",
  "当前节点处理人": "The current node processor",
  "学生资料": "Student Profile",
  "个人信息-基本信息": "Personal Information - Basic Information",
  "个人信息-账号信息": "",
  "个人信息-邮寄地址": "",
  "服务信息-服务信息": "Service Information - Service Information",
  "服务信息-学生画像": "Service Information - Student Profile",
  "服务信息-家长画像": "",
  "当前学情-中文课程进度": "Current learning situation - Chinese course progress",
  "当前学情-中文教学期望": "Current learning – Chinese teaching expectations",
  "当前学情-中文其他学情": "Current learning - Chinese other academic situation",
  "当前学情-数学课程进度": "Current learning situation - Mathematics course progress",
  "当前学情-数学教学期望": "Current learning situation - Mathematics teaching expectations",
  "当前学情-数学其他学情": "Current Academic Situation - Other Academic Situation of Mathematics",
  "入学学情-中文体验课信息": "Admission Information - Chinese Experience Class Information",
  "入学学情-中文学习计划": "Admission to the School - Chinese Study Plan",
  "入学学情-中文入学学情": "",
  "入学学情-数学体验课信息": "Admission Information - Math Experience Class",
  "入学学情-数学学习计划": "",
  "入学学情-数学入学学情": "",
  "班主任-跟进记录": "Class Teacher - Follow-up Notes",
  "李荷兰": "Lee Holland",
  "顾问-跟进记录": "Consultant - Follow-up record",
  "互动作业": "",
  "纸质作业(家长明确要求)": "Paper assignments (explicitly requested by parents)",
  "Classin布置作业": "Classin assigns homework",
  "不布置作业(家长明确要求)": "No homework assignment (explicitly requested by parents)",
  "未选择": "Not selected",
  "请输入学号": "Please enter your student number",
  "订单创建时间": "Order creation time",
  "实时汇率": "",
  "币种": "Currency",
  "订单学员": "",
  "截止有效期": "",
  "添加跟进记录": "Add a follow-up record",
  "跟进科目": "Follow up on the subjects",
  "跟进方式": "Follow-up methods",
  "电话结果": "Phone results",
  "身份职责": "",
  "学员信息": "Learner information",
  "入学课包": "",
  "新生权益": "Freshman rights",
  "教学期望": "Pedagogical expectations",
  "学习计划": "Study plan",
  "看课反馈": "Watch class feedback",
  "首课感受": "First lesson feelings",
  "级别进度": "Level progress",
  "作业提醒": "Job reminders",
  "寄送确认": "",
  "分享推荐": "Share testimonials",
  "学情分析": "",
  "建议：作近期作业完成情况，学习进步点待提高点等": "Suggestions: Make recent homework completion, learning progress points to be improved, etc",
  "补升方案介绍": "",
  "建议：介绍优惠的唯一性，性价比，孩子学习情况等": "",
  "学习规划制定": "Study planning",
  "建议：学习规划表，课频建议，XX级别学习目标，级别进度等": "Suggestions: study planning table, lesson frequency suggestions, XX level learning objectives, level progress, etc",
  "补升意向": "",
  "家长回暖": "Parents pick up",
  "参考：询问近期近况，孩子学情，可包含疫情（复课）、学校近况、天气等寒暄": "Reference: Ask about the recent situation, the child's learning situation, which can include the epidemic (resumption of classes), school situation, weather and other greetings",
  "沟通内容": "Communication content",
  "参考：专题课推荐，利好政策同步等": "Reference: Special course recommendation, favorable policy synchronization, etc",
  "复课计划": "",
  "复课时间": "Resumption of classes",
  "阶段学情总结": "",
  "家长反馈": "Parent feedback",
  "出勤次数": "",
  "缺席/迟到原因": "Reasons for absence/tardiness",
  "学习规划": "Study planning",
  "学情反馈": "",
  "参考：近期中文学习进度（出勤、上课专注度等学习情况）作业完成情况评价/老师批改作业情况反馈": "Reference: Recent Chinese learning progress (attendance, class concentration, etc.), homework completion evaluation/teacher correction feedback on homework",
  "核对学情": "Check the academic situation",
  "身份介绍": "Introduction to identity",
  "遗留问题": "Legacy issues",
  "转移类型": "Transfer type",
  "转移学员学号": "Transfer the student number",
  "请选择转移学生": "Please select Transfer Student",
  "接收学员学号": "Receive a student student number",
  "请选择接收学生": "",
  "折扣情况": "Discount situation",
  "课时转移记录": "Lesson transfer records",
  "课时转移原因": "",
  "旷课原因": "",
  "补课安排": "",
  "质检学科": "Quality inspection discipline",
  "质检类型": "Quality inspection type",
  "质检来源": "Quality inspection source",
  "质检判定": "Quality inspection judgment",
  "奖惩分数": "Reward and punishment scores",
  "奖惩金额": "",
  "请填写-500~500的整数数字": "Please fill in the integer number -500~500",
  "质检判定时间": "Quality inspection judgment time",
  "责任人": "Responsible person",
  "质检人员": "Quality inspectors",
  "质检描述": "",
  "模版：学员活泼好动，能听懂授课老师的指令，但开口比较少；建议家长多鼓励小朋友，整体难度是适合孩子的": "Template: Students are lively and active, can understand the instructions of the teacher, but speak less; It is recommended that parents encourage children more, and the overall difficulty is suitable for children",
  "交接备注": "Handover notes",
  "参考：如对课件及首课老师不满，请在此备注原因，家长异议及沟通结果，其他": "Reference: If you are dissatisfied with the courseware and the first lesson teacher, please note the reason, parental objection and communication results, etc",
  "参考最新转介绍活动": "Refer to the latest referral activities",
  "家长有无意见反馈": "",
  "家长意见反馈": "",
  "参考：学员近期学习兴趣不高......": "",
  "参考：当前阶段的学习做简单的总结，从课频、孩子学习习惯、课堂表现等，描述学员本阶段进步点（某个细节）+夸细节反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会 ": "",
  "互动作业提醒": "",
  "作业完成度（准时完成、偶尔未提交/未完成、从不完成作业），作业中较为突出的优秀点，学员薄弱点，鼓励学员课后完成互动作业及时达到巩固效果 ": "",
  "是否发送报告": "Whether to send the report",
  "学习建议": "",
  "参考：提供相应的学习资料或课程提升孩子薄弱项 ": "",
  "课程预告": "Course Trailer",
  "参考：铺垫下阶段的学习内容，需要注意的重难点是什么": "Reference: What are the key and difficult points that need to be paid attention to in laying the learning content in the next stage",
  "是否邮寄教辅资料": "",
  "参考：做后期学习规划+铺单": "Reference: Do post-learning planning + paving orders",
  "学情交接": "Transfer of learning and feelings",
  "课堂参与度": "Classroom engagement",
  "出勤情况": "Attendance",
  "分享参与": "",
  "不积极原因": "Not positive reasons",
  "教学服务信息": "",
  "老师旷课原因": "Reasons for teacher absenteeism",
  "其他原因": "Other reasons",
  "是否同意注册试听": "Whether you agree to register for the audition",
  "不注册试听原因": "Audition reasons are not registered",
  "约课是否成功": "Whether the appointment was successful",
  "未约课原因": "Reasons for non-appointments",
  "参考：学员近期学习兴趣不高...... ": "Reference: Students are not interested in learning in the near future...",
  "学情总结": "",
  "参考：班主任描述孩子这5节课的进步点（某个细节）+夸细节、反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会； ": "Reference: The class teacher describes the child's progress point (a certain detail) in these 5 lessons + exaggerates the details, feedback weaknesses + and discusses with the mother whether this weak point is really not;",
  "是否确认不续费": "Whether to confirm that the fee will not be renewed",
  "不续费原因": "",
  "不续费次要原因": "Secondary reasons for non-renewal",
  "提示：如三级分类均无，请详细描述次要原因": "",
  "是否取消所有课程": "Whether to cancel all courses",
  "班主任沟通概述": "",
  "提示：请概述家长不续费原因和续费沟通的次数及内容": "Tip: Please outline the reasons for non-renewal and the number and content of renewal communications",
  "是否清空赠课额度": "Whether to clear the free course quota",
  "是否补升": "",
  "家长异议": "",
  "提示：对比入学变化 ;总结学员5节课和单元测评学习状态以及学习进度（作业和上课表现） ;家长想法和感受（解决异议，预防退费） ;孩子最近感受和上课积极性 ;家长对课程的看法（有无意见和建议）  ": "Tip: Compare changes in admission; Summarize the learning status and learning progress (homework and class performance) of the 5 lessons and units; Parents' thoughts and feelings (resolving objections, preventing refunds); The child's recent feelings and motivation in class; Parents' views on the curriculum (with or without comments and suggestions)",
  "提示： 预期管理 ;对比同年龄同级别学员学习（好的可以表扬，差一点的不用细说，可以说跟之前的进步，鼓励孩子） ;肯定鼓励表扬现阶段学习成果 ;保持目前学习节奏 ;针对薄弱点或者掌握不好的主题提供资料（handbook或者作业文档），加强复习巩固 ": "",
  "提示：后面5节课课程难度变化，XX节课需要提前做XX准备": "Tips: The difficulty of the next 5 lessons changes, and XX lessons need to be prepared in advance",
  "提示：近2节课的学习做一个简单的总结从课堂表现课后作业；孩子前后课堂的具体的变化点）总结优点，缺点针对性做后续的学习规划调整，需要家长配合什么等": "Tips: Make a simple summary of the last 2 lessons of learning from class performance homework; The specific changes in the child's before and after the classroom) summarize the advantages, disadvantages, and make subsequent learning planning adjustments, what parents need to cooperate with, etc",
  "跟进描述": "Follow up the description",
  "跟进结果": "Follow up on the results",
  "意向度": "Degree of intent",
  "下次跟进时间": "Next follow-up time",
  "下次跟进描述": "Next follow-up description",
  "同时完成任务": "Complete tasks at the same time",
  "未告知": "Not informed",
  "有，在跟进描述中补充说明原因": "Yes, add the reason in the follow-up description",
  "否，在跟进描述中补充说明原因": "No, add the reason in the follow-up description",
  "已发送": "Sent",
  "未发送": "",
  "已补升": "",
  "放弃补升权益": "Waiver of top-up benefits",
  "价格": "Price",
  "学习效果": "Learning effects",
  "无异议": "No objections",
  "未取消": "Not canceled",
  "已清空": "",
  "未清空": "Not cleared",
  "已发起": "Initiated",
  "未发起": "",
  "学员原因-学员时间紧张": "Reason for trainees - Trainees are short on time",
  "学员原因-无数学补习需求": "Reason for students - no need for math tutoring",
  "学员原因-已有线下数学课": "Reason for students - There are already offline math classes",
  "学员原因-授课语言不匹配": "Reason for the student - language of instruction does not match",
  "学员原因-已有Online数学课": "Why students - There are already online math lessons",
  "课程产品-可试听时间不适合": "Course Products - Auditionable time is not suitable",
  "课程产品-不认可1对多班型": "Course Products - 1-to-multi-class type is not recognized",
  "课程产品-想学启蒙基础课": "",
  "不同意": "disagree",
  "成功": "succeed",
  "失败": "",
  "学员原因-学员年龄不符合": "Reason for the trainee - the age of the trainee does not match",
  "未补课": "No make-up lessons",
  "已安排补课": "Make-up classes have been scheduled",
  "忘记上课、忘记请假": "Forgot to go to class, forgot to ask for leave",
  "操作系统无法登陆": "The operating system cannot log in",
  "停电、断网、电脑故障": "",
  "突发原因：停电、生病、断网等": "Sudden causes: power outage, illness, network disconnection, etc",
  "积极参与": "",
  "一般，不积极": "General, not positive",
  "不参与，很排斥": "",
  "已更新完善教学期望": "Teaching expectations have been updated to improve teaching expectations",
  "已更新完善服务信息": "",
  "对新老师满意，交接成功": "Satisfied with the new teacher and successful handover",
  "对老师不满意，需更换老师": "",
  "投诉": "complaints",
  "流失": "Loss",
  "质检": "Quality",
  "优秀案例": "",
  "质检抽检": "Quality inspection and sampling",
  "约课顾问": "Appointment Consultant",
  "教学部": "Department of Teaching",
  "客服渠道": "Customer service channels",
  "奖励加分": "",
  "有责扣罚": "There is a penalty for liability",
  "无责不扣罚": "",
  "已请假，系统原因未取消课节": "Leave has been requested, and the lesson has not been cancelled for system reasons",
  "已请假，人为原因未取消课节": "Leave has been requested, but the lesson has not been cancelled for human reasons",
  "不知道如何请假": "Don't know how to take time off",
  "忘记请假": "Forget to ask for leave",
  "待转移课时无折扣": "",
  "待转移课时有折扣": "There is a discount for the time of the transfer lesson",
  "中文课时不同学员互转": "Chinese lesson is interchangeable between different students",
  "Math课时不同学员互转": "",
  "中文转移数学": "Chinese transfer mathematics",
  "数学转移中文": "",
  "无遗留问题": "No legacy issues",
  "有遗留问题待处理（请在跟进描述中详细备注）": "There are remaining issues to be addressed (please note in detail in the follow-up description)",
  "已介绍": "Introduced",
  "暂未，在跟进描述中补充说明原因": "For the time being, add the reasons in the follow-up description",
  "和系统信息一致": "Consistent with system information",
  "不一致，请描述需补充的内容": "Inconsistent, please describe what needs to be added",
  "低于50%，需关注（需在备注中记录沟通结果）": "Less than 50%, need attention (need to record the results of communication in the remarks)",
  "低": "low",
  "中": "middle",
  "高": "high",
  "学习效果不理想": "",
  "价格不划算": "",
  "授课老师不满意": "",
  "消费观保守": "The view of consumption is conservative",
  "其他（请在跟进描述中做好备注）": "Others (please make a note in the follow-up description)",
  "准时完成": "Completed on time",
  "偶尔未提交/未完成": "Occasionally uncommitted/incomplete",
  "从不完成作业": "Never complete a job",
  "已推广，转介绍活动": "Promoted, referral activities",
  "未推广，在跟进描述中补充说明原因": "Not promoted, add the reason in the follow-up description",
  "已确认，并安排寄送": "",
  "未确认，在跟进描述中补充说明原因": "Not confirmed, add the reason in the follow-up description",
  "作业提交方法已同步，已提醒": "",
  "当前级别合适，不需要调整": "The current level is appropriate and does not require adjustment",
  "当前级别不合适，需调整，在跟进描述中补充说明原因": "The current level is not suitable and needs to be adjusted, and the reasons are added in the follow-up description",
  "对授课老师、课件教学内容均满意": "I am satisfied with the teaching teacher and courseware teaching content",
  "对老师满意；对课件教学内容不满意": "Satisfaction with the teacher; Not satisfied with the teaching content of the courseware",
  "对课件内容满意；对首课老师不满意": "Be satisfied with the content of the courseware; Dissatisfied with the teacher of the first lesson",
  "均不满意，已提交审批": "",
  "魏老师": "",
  "班型（私教课、班课）已核对": "Class type (private lesson, class lesson) has been checked",
  "入学级别已核对": "Entry levels have been checked",
  "上课频率（每周）已核对": "",
  "学员可排课时间已核对": "",
  "授课风格已核对": "The teaching style has been checked",
  "教学特殊要求已核对": "",
  "确认主要学习负责人手机号和邮箱（App账号）": "",
  "确认回访沟通方式偏好（服务群文字or语音电话or邮件）": "Confirm the preference for return visit communication methods (service group text, voice, phone or email)",
  "已全部核对并补充，系统记录已校准并补充": "",
  "部分未核对补充，在跟进描述中补充未核对部分": "The partially unchecked additions are supplemented in the follow-up description",
  "未核对，在跟进记录中补充原因": "Failure to check, add the reason in the follow-up record",
  "悟空学员中心App/Web介绍，引导下载App": "Wukong Student Center App/Web introduction, guide to download the app",
  "新生补升权益": "",
  "班课调课/私教课请假规则": "Class transfer/private lesson leave rules",
  "退费规则": "",
  "24H在线客服介绍-App IM功能": "24H online customer service introduction - App IM function",
  "已核对入学课时，含购买课时、购买赠课": "Checked admission hours, including purchase of class hours, purchase of free courses",
  "已核对分享赠课额度及规则": "",
  "基本信息已全部核对并补充": "The basic information has been checked and supplemented",
  "上课账号已核对（App账号，上课提醒接收）": "Class account checked (App account, class reminder received)",
  "未完成核对，后续补充": "The check was not completed, and it was supplemented later",
  "暂存草稿": "Staged the draft",
  "仍要关闭": "Shut down anyway",
  "逾期": "Late",
  "查看课表": "Check the class schedule",
  "去补升": "Go to make up",
  "建群": "Build a group",
  "确认订单": "",
  "交接入群": "",
  "当前账号暂无权限访问本系统": "",
  "你可以去": "You can go",
  "修改": "",
  "做一做": "",
  "主观题图片": "Subjective question picture",
  "学生提交内容": "Student submissions",
  "学生主观题图片": "Pictures of student subjective questions",
  "老师评分": "Teacher grading",
  "点击输入老师评语": "",
  "完成批阅": "",
  "学生还未提交作业": "",
  "报告还未提交": "The report has not yet been submitted",
  "题目序号": "Title serial number",
  "题干": "Question stem",
  "错误次数": "Number of errors",
  "答题时间": "Answer time",
  "AI分析": "AI analytics",
  "正确": "That's right",
  "绘本语句": "Picture book statements",
  "朗读得分": "",
  "朗读语音": "Read speech aloud",
  "历史详情": "",
  "修改题目": "Modify the title",
  "支持MP3格式，文件不超过5分钟，大小不超过5M": "Support MP3 format, file no more than 5 minutes, size no more than 5M",
  "上传音频": "",
  "图片最多5张，每张大小不超过3M": "There are up to 5 images, each with a size of no more than 3M",
  "视频最多2个，每个大小不超过50M": "Up to 2 videos, each with a size of no more than 50M",
  "上传视频": "Upload a video",
  "文件最多2个，支持PDF，每个大小不超过50M": "Up to 2 files, support PDF, each size does not exceed 50M",
  "上传文件": "Upload the file",
  "保存并发布": "Save and publish",
  "确认完成": "Confirm completion",
  "的补升情况，确认后该任务将标记为已完成": "",
  "已购买补升": "",
  "家长已完成支付，且财务已确认到账的": "The parent has completed the payment and the financial confirmation has arrived",
  "已核实该学员放弃补升": "It has been verified that the student has waived the replacement",
  "多次与家长沟通后，家长依然坚持放弃补升权益的": "After communicating with parents many times, parents still insist on giving up the right to make up promotion",
  "去沟通": "Go to communicate",
  "你已成功创建与学生": "",
  "1.完成自我介绍": "",
  "2.与家长约定「新生首通」的时间": "",
  "补升超期提醒": "Make-up overdue reminder",
  "立刻前往": "Go now",
  "稍后前往": "Go later",
  "你已成功完成学生": "You have successfully completed the student",
  "课程推荐": "Course recommendations",
  "中文海报": "Chinese poster",
  "数学海报": "",
  "点击生成": "Click Generate",
  "的专属海报": "Exclusive poster",
  "生成专属海报": "Generate your own poster",
  "批量下载": "Batch downloads",
  "开始下载": "Start downloading",
  "请先选择要下载海报的学生": "Please select the student you want to download the poster for first",
  "已选": "Selected",
  "人": "",
  "海报详情": "Poster details",
  "复制": "copy",
  "唯一键": "Unique key",
  "请填写唯一键": "Please fill in a unique key",
  "角色名称": "Role name",
  "请填写角色名称": "Please fill in the role name",
  "备注说明": "Remarks description",
  "拥有权限": "Have permissions",
  "全选": "Select all",
  "请从列表中选择班课插班": "Please select the class transfer from the list",
  "留在原班看回放": "Stay in the original class to watch the replay",
  "调入": "Tune in",
  "课节人数": "Number of lessons",
  "班课插班": "",
  "排课列表": "",
  "请选择课节状态": "Please select the lesson status",
  "星 期": "Star period",
  "请选择星期": "Please select the week",
  "排课": "Schedule lessons",
  "批量取消": "Bulk cancellation",
  "批量修改助教": "Modify teaching assistants in bulk",
  "调整时间": "Adjust the time",
  "修改授课老师": "Change the instructor",
  "调整课节星期": "Adjust the week of the lesson",
  "(代课)": "(Substitute)",
  "调级": "Leveling",
  "调时间": "Adjust the time",
  "学生列表": "A list of students",
  "添加学生": "Add students",
  "挪出": "Move out",
  "修改课节": "Modify the lesson section",
  "助教老师": "Teaching assistant teacher",
  "请选择助教老师": "Please select a teaching assistant",
  "上台人数": "",
  "上台人数(1-12数字)": "Number of people on stage (1-12 numbers)",
  "调整课节级别": "Adjust lesson levels",
  "将原级别": "",
  "新级别": "New level",
  "请选择新级别": "Please select a new level",
  "是否将之后的所有课节": "Whether to move all subsequent lessons",
  "按新级别重排": "",
  "取消排课": "Cancel a class schedule",
  "确定要取消": "Be sure you want to cancel",
  "请选择取消原因": "Please select a reason for cancellation",
  "请输入其他原因": "",
  "新授课老师": "New instructors",
  "请选择新授课老师": "Please select a new instructor",
  "是否代课": "Whether or not to substitute",
  "临时代课": "Temporary substitute classes",
  "长期交接(将之后课节都更换为此老师)": "Long-term handover (replacement of this teacher for subsequent lessons)",
  "原因": "cause",
  "是否将之后的所有课节都换为此老师": "",
  "调整课节时间": "Adjust lesson times",
  "新时间": "New time",
  "请选择时间间隔": "Please select a time interval",
  "按新时间间隔重排": "Rearrange by new interval",
  "请选择学生(支持学号，姓名搜索)": "Please select a student (support student number, name search)",
  "开始日期": "Start date",
  "将": "will",
  "选择新时间": "Select a new time",
  "新课节星期": "New lessons are weekly",
  "请选择课节星期": "Please select the week of the lesson",
  "新课节时分": "",
  "学生容量": "Student capacity",
  "班级时区": "Class time zone",
  "课程名称": "Course name",
  "开课级别": "Start level",
  "学生数量": "Number of students",
  "星期": "week",
  "课节时长": "Lesson length",
  "插班生": "Transfer students",
  "调出生": "Tune birth",
  "状态原因": "",
  "国家/地区": "Country/Region",
  "开始时间": "",
  "付费状态": "Paid status",
  "周一": "Monday",
  "周二": "Tuesday",
  "周三": "Wednesday",
  "周四": "Thursday",
  "周五": "",
  "周六": "Saturday",
  "周日": "Sunday",
  "请输入课节名称": "Please enter a lesson name",
  "请选择助教": "Please select a teaching assistant",
  "请输入上台人数(1-12整数)": "Please enter the number of people on stage (1-12 integers)",
  "删除学生": "Delete a student",
  "班级容量": "Class capacity",
  "请输入班级容量(1-1000数字)": "",
  "请输入上台人数(1-12数字)": "Please enter the number of people on stage (1-12 numbers)",
  "课程": "course",
  "班主任老师": "Homeroom teacher",
  "请输入班级名称": "",
  "请选择班级类型": "Please select a class type",
  "请选择课程": "Please select a course",
  "请选择时区": "",
  "请选择班主任老师": "Please select a homeroom teacher",
  "一类级别": "",
  "二类级别": "",
  "排课周期": "",
  "剩余课节": "Remaining lessons",
  "全部": "all",
  "等于0": "",
  "大于0": "Greater than 0",
  "大于0 小于5": "",
  "大于0 小于10": "Greater than 0 Less than 10",
  "大于0 小于20": "Greater than 0 Less than 20",
  "新建班级": "",
  "结课": "End of class",
  "下一课信息": "Next lesson information",
  "课程科目": "Course subjects",
  "排课类型": "Scheduling type",
  "新排课": "New schedules",
  "加课": "Additional lessons",
  "开始课节": "",
  "请选择开始课节": "",
  "结束课节": "End the lesson",
  "请选择结束课节": "",
  "课节数": "Number of lessons",
  "选择开课日期": "Select a start date",
  "开始": "Begin",
  "结束": "Come to an end",
  "老师属性": "",
  "授课风格": "Teaching style",
  "能力星级": "Ability star",
  "可排级别": "",
  "指定老师": "",
  "老师姓名": "",
  "搜索老师": "Search for a teacher",
  "老师列表": "List of teachers",
  "教师简介": "Teacher profile",
  "暂无内容": "No content",
  "教学理念": "Teaching philosophy",
  "请勿泄露以下信息": "",
  "暂无课节回放": "",
  "课节回放": "Lesson playback",
  "考核影像记录": "Assessment image recording",
  "老师课表": "Teacher's schedule",
  "(已签约)": "(Contracted)",
  "排课结果": "Schedule results",
  "设置助教": "",
  "确认排课": "Confirm class scheduling",
  "跳过该时间,完成排课": "Skip that time and complete the schedule",
  "取消本次排课": "Cancel this schedule",
  "老师可用时间": "Teacher available time",
  "排课时间": "Schedule time",
  "请选择开始日期": "Please select a start date",
  "请选择排课周期": "Please select a schedule period",
  "发单课节信息": "Issue lesson information",
  "相关课节(北京)": "Related Lessons (Beijing)",
  "期望老师授课风格": "Expect the teacher's teaching style",
  "期望老师性别": "",
  "发单有效期": "Billing validity period",
  "发单备注": "Billing notes",
  "立即发单": "",
  "放弃": "abandon",
  "确定发此": "Make sure to send this",
  "紧急代课单": "",
  "发单后，授课老师将进行抢单；授课老师抢单前您可取消该单": "",
  "30分钟": "30 minutes",
  "1小时": "1 hour",
  "2小时": "2 hours",
  "3小时": "",
  "6小时": "6 hours",
  "12小时": "12 hours",
  "24小时": "",
  "发单类型": "Billing type",
  "待接单": "",
  "已接单": "Order received",
  "超时已取消": "The timeout has been canceled",
  "抢单结果": "Grab the results",
  "确定取消": "Confirm the cancellation",
  "确定取消后，授课老师将不会再收到该单的通知": "Once the cancellation is confirmed, the instructor will no longer be notified of the ticket",
  "排课单": "",
  "紧急排课单": "Emergency schedule",
  "抢单人(授课老师)": "Single robbery (teacher)",
  "请假学生": "",
  "请假状态": "Leave status",
  "调课": "",
  "请假课节": "Take time off from class",
  "开课日期": "",
  "是否调课": "Whether to transfer classes or not",
  "申请时间": "Application time",
  "放弃调课": "Abandon class transfers",
  "插班": "Cut in",
  "请假": "leave",
  "请填写原因(必填)": "Please fill in the reason (required)",
  "请填写原因": "Please fill in the reason",
  "私教课紧急请假": "",
  "出勤": "Attendance",
  "迟到": "Late",
  "早退": "Early",
  "取消学生": "Cancel a student",
  "自动更新之后章节": "Automatically update subsequent chapters",
  "体验课可用": "",
  "标准课可用": "",
  "通用时间": "Universal time",
  "已排课": "",
  "不可用": "not available",
  "小时": "hour",
  "「总工作时长」为可用时间与已排课时间总和（去重），再减去重合的不可用时间": "\"Total Working Hours\" is the sum of available time and scheduled class time (deduplication), minus coincident unavailable time",
  "添加不可用时间（春节）": "Add unavailable time (Spring Festival)",
  "反馈已提交": "Feedback submitted",
  "您可随时前往": "You can go at any time",
  "查看进度": "View the progress",
  "确认修改课节": "Confirm the modification of the lesson",
  "请确认课节进度是否正确，并严格按照该进度上课": "Please confirm that the lesson progress is correct and follow the class according to this pace",
  "设置筛选": "Set up filtering",
  "请选择筛选项": "",
  "删除此行": "Delete this line",
  "自定义字段": "Custom fields",
  "搜索想要的字段": "Search for the field you want",
  "当前列表展示": "The current list is displayed",
  "课时变更": "Lesson changes",
  "变更原因": "Reason for the change",
  "转入学员": "Transfer to trainees",
  "请选择学员": "Please select a learner",
  "转入科目": "Transfer to an account",
  "转移数量": "Number of transfers",
  "补偿课时": "Compensate for class hours",
  "分享赠课额度": "Share the free class credit",
  "转移额度": "",
  "课时数量": "Number of lessons",
  "学员状态变更": "",
  "变更学员": "Change learner",
  "变更状态科目": "Change status accounts",
  "当前状态(变更前)": "Current status (before change)",
  "变更状态为": "The change status is",
  "延期/冻结开始日期": "Extension/freeze start date",
  "延期/冻结结束日期": "Extension/freeze end date",
  "线索学员": "Lead learners",
  "线索详情": "Lead details",
  "所属科目": "Belongs to the account",
  "线索关联学员": "Clue association learners",
  "线索约课": "Clues to make an appointment",
  "订单确认页": "",
  "选择订单商品": "Select the order item",
  "核对订单信息": "Reconcile the order information",
  "确认无误，立即下单": "Confirm that the order is correct, and place the order immediately",
  "学员订单": "Learner orders",
  "订单商品": "Order items",
  "添加商品": "Add products",
  "无效价格": "",
  "订单备注": "Order notes",
  "订单有效期": "Order validity period",
  "提交订单": "",
  "返回上一页": "Go back to the previous page",
  "选择商品": "Select the product",
  "2天": "2 days",
  "3天": "3 days",
  "4天": "",
  "5天": "5 days",
  "6天": "6 days",
  "7天": "7 days",
  "优惠": "Preferential",
  "税": "tax",
  "小计": "subtotal",
  "赠课": "Complimentary lessons",
  "L系列悟空宝盒": "L series Goku treasure box",
  "G系列悟空宝盒": "",
  "S系列悟空宝盒": "",
  "团购": "Buy",
  "专题课": "Thematic lessons",
  "上传类型：": "Upload Type:",
  "上传数据": "Upload data",
  "上传类型": "Upload type",
  "选择文件": "Select the file",
  "数据上传成功": "The data is uploaded successfully",
  "数据上传失败": "Data upload failed",
  "点击下载失败文件，修改后可重新上传": "Click Download failed file to modify it and upload it again",
  "忽略并关闭": "Ignore and close",
  "下载失败文件": "Download failed file",
  "文件名": "",
  "上传结果": "Upload the results",
  "上传人": "Uploaded by",
  "数据上传时间": "Data upload time",
  "数据更新时间": "Data update time",
  "班主任业务数据": "Class teacher business data",
  "申请退款": "",
  "退款课时明细": "Refund details of lesson hours",
  "编辑寄送": "Edit sending",
  "确认退款信息": "",
  "退款金额": "Amount of refund",
  "退款备注": "",
  "课件": "Courseware",
  "教材作业": "Textbook assignments",
  "课程体系其他": "Curriculum system other",
  "老师教学/服务": "Teacher teaching/service",
  "班主任服务": "",
  "顾问服务": "Consultancy services",
  "系统": "system",
  "规则": "rules",
  "学生原因": "Student reasons",
  "家长原因": "Parental reasons",
  "竞品": "Competitors",
  "家长拒绝进一步沟通": "Parents refused further communication",
  "课时类型": "Type of lesson",
  "剩余课时或额度": "Remaining class hours or credits",
  "可退课时或额度": "Refundable hours or credits",
  "分享赠课": "Share free lessons",
  "已批准": "Approved",
  "花果": "Flowers and fruits",
  "学员中心APP": "Learner Center APP",
  "学员中心小程序": "Learner Center Mini Program",
  "赠课数量": "Number of lessons gifted",
  "审批时间": "Approval time",
  "分享赠课记录": "Share lesson records",
  "变更记录": "Change History",
  "总课时": "Total class hours",
  "暂无已扣课时": "No class hours have been deducted",
  "暂无补偿课时": "There are no compensation hours",
  "剩余/总额度": "Remaining/gross magnitude",
  "当前学员线索": "Current learner leads",
  "学员推荐线索": "Learner referral leads",
  "状态变更": "Status change",
  "中文昵称": "Chinese nickname",
  "英文昵称": "",
  "修改classin账号": "",
  "将原classin": "Place the original classin",
  "账号修改为": "Modify the account number to",
  "新classin账号": "New classin account",
  "区号": "Code",
  "修改昵称": "Modify the nickname",
  "原中文昵称": "Original Chinese nickname",
  "跟进": "follow up",
  "线索": "clue",
  "分享": "",
  "中文调班课表": "Chinese shift schedule",
  "数学调班课表": "Math Shift Schedule",
  "更多": "",
  "学员任务": "Trainee tasks",
  "更新首课": "Update the first lesson",
  "中文首课": "Chinese first lesson",
  "数学首课": "First lesson in mathematics",
  "近30天作业完成率": "Job completion rate in the last 30 days",
  "近30天出勤率": "Attendance for nearly 30 days",
  "学员课表": "",
  "今日课节": "Today's lessons",
  "服务": "serve",
  "学习": "",
  "分层": "delamination",
  "更新首课时间": "Update the first lesson time",
  "国别号": "Country code",
  "中文学生状态": "Chinese student status",
  "中文剩余课时": "Chinese remaining lessons",
  "中文剩余额度": "Chinese remaining credit",
  "中文当前级别": "Chinese current level",
  "中文顾问": "Chinese consultant",
  "中文推荐赠课": "Chinese recommended free lessons",
  "中文购买课时": "Chinese purchase lessons",
  "中文社交赠课": "Chinese social lessons",
  "中文班型": "",
  "中文班级": "Chinese class",
  "中文入学时间": "Chinese the time of admission",
  "中文首课时间": "Chinese first lesson time",
  "数学学生状态": "Math student status",
  "数学剩余课时": "Remaining math hours",
  "数学剩余额度": "Math remaining credit",
  "数学当前级别": "",
  "数学顾问": "Mathematics Advisor",
  "数学推荐赠课": "Mathematics recommended lessons",
  "数学购买课时": "",
  "数学社交赠课": "Math social lessons",
  "数学班型": "Math class type",
  "数学班级": "Math class",
  "数学入学时间": "Math enrollment time",
  "数学首课时间": "Time for the first lesson in mathematics",
  "中文补升权益": "Chinese Supplementary Equity",
  "数学补升权益": "Math Make-up Equity",
  "中文作业偏好": "Chinese job preferences",
  "数学作业偏好": "",
  "中文跟进策略": "Chinese follow-up strategy",
  "数学跟进策略": "Math follow-up strategies",
  "中文续费池": "Chinese renewal pool",
  "数学续费池": "Math renewal pool",
  "中文近3周课消": "Chinese the past 3 weeks of class elimination",
  "数学近3周课消": "Math has been eliminated for nearly 3 weeks",
  "中文作业提交率": "Chinese job submission rate",
  "数学作业提交率": "",
  "中文作业正确率": "Chinese job accuracy",
  "数学作业正确率": "",
  "中文本周分享": "Chinese share this week",
  "数学本周分享": "Math shared this week",
  "中文近3月进线": "",
  "数学近3月进线": "Mathematics has advanced in the past 3 months",
  "中文更换班主任数": "",
  "数学更换班主任数": "Number of math replacement class teachers",
  "新建群发": "Create a new group send",
  "群发人群数据正在请求中，您可先编辑群发内容，等待数据请求完成...": "Mass sending crowd data is being requested, you can edit the mass sending content first, wait for the data request to complete...",
  "送达人数": "Number of deliveries",
  "执行员工数": "Number of executive employees",
  "附带专属海报小程序": "Comes with an exclusive poster mini program",
  "立即提醒": "",
  "定时提醒": "Scheduled reminders",
  "中文近3个月进线": "Chinese entered the line in the past 3 months",
  "数学近3个月进线": "Mathematics has been in the line in the past 3 months",
  "中文更换班主任次数": "Chinese the number of changes in class teachers",
  "数学更换班主任次数": "",
  "专属海报": "Exclusive poster",
  "跟进策略": "",
  "周期课时信息": "Periodic lesson information",
  "获客渠道": "Customer acquisition channels",
  "获客来源": "Source of customer acquisition",
  "付款方式": "Payment method",
  "体验周期": "Experience the cycle",
  "转化周期": "Conversion cycle",
  "开课时长": "Course duration",
  "历史沉睡次数": "",
  "历史沉睡时长": "The length of time history sleeps",
  "近3个月课消": "Classes have disappeared in the past 3 months",
  "近3周课消": "Classes have disappeared in the past 3 weeks",
  "上上周完课": "",
  "上周完课": "Finished class last week",
  "本周已上已约": "This week has been on the appointment",
  "下周已约": "Appointment for next week",
  "辅修课占比": "Proportion of minor courses",
  "最后一次付款月": "Last payment month",
  "历史复购次数": "Number of historical repurchases",
  "剩余课时分档": "",
  "续费池": "Renewal pool",
  "作业信息": "Job information",
  "近4周作业提交率": "Assignment submission rate in the last 4 weeks",
  "近4周作业正确率": "Correct rate of work in the past 4 weeks",
  "分享信息": "Share information",
  "近3个月分享": "Sharing in the last 3 months",
  "上周分享": "Share last week",
  "本周分享": "Share this week",
  "近3个月进线": "Entered the line in the past 3 months",
  "近3个月节点及时率": "Node timeliness in the past 3 months",
  "近3个月节点完成率": "Node completion rate in the past 3 months",
  "更换班主任次数": "Number of changes to class teachers",
  "创建订单": "Create an order",
  "结业证书": "Certificate of completion",
  "报告详情": "Report details",
  "阶段名称": "Stage name",
  "分类": "classify",
  "作业偏好": "Job preferences",
  "级别（Ⅰ）": "Level (I.)",
  "级别（Ⅱ）": "Level (II.)",
  "级别（Ⅲ）": "Level (III.)",
  "开班时间（北京）": "Start Time (Beijing)",
  "家长学习期待": "Parents look forward to learning",
  "学生兴趣爱好": "Student hobbies",
  "试学期优惠": "Trial semester discount",
  "入学班次": "Admission class",
  "阅读课(北京时间)": "Reading Class (Beijing Time)",
  "已参与": "Participated",
  "未参与": "Not engaged",
  "体验课详情": "Details of the trial class",
  "作业详情": "Job details",
  "作业正确率": "Job accuracy",
  "作业提交时间": "Job submission time",
  "阶段报告": "Stage report",
  "阶段证书": "Stage certificate",
  "答题正确率": "Correct answers",
  "教师评分": "",
  "上台时长": "Length of time on stage",
  "奖励次数": "Number of rewards",
  "举手次数": "Number of hand raises",
  "上课类型": "Type of class",
  "立即排课": "Schedule classes now",
  "请使用已有邮箱进行账号绑定如有疑问请联系IT服务台": "Please use your existing email address to bind your account, and if you have any questions, please contact the IT Service Desk",
  "立即绑定": "Bind now",
  "注册新账号": "Sign up for a new account",
  "此账号暂无登录权限": "This account does not have login permissions",
  "欢迎": "",
  "错误": "mistake",
  "我已阅读并同意用户协议和隐私政策": "I have read and agree to the User Agreement and Privacy Policy",
  "登录失败：该邮箱还未注册": "Login failed: The email address has not been registered",
  "登录失败：密码不正确": "Login failed: The password is incorrect",
  "返回": "return",
  "该邮箱还未注册": "The email address is not registered yet",
  "立即注册": "Sign up now",
  "该邮箱已注册": "The email address is already registered",
  "忘记密码": "Forgot password",
  "未检测到邮箱录入信息，请联系管理员": "If the mailbox entry information is not detected, please contact the administrator",
  "好的": "Good",
  "新密码不能和旧密码相同": "The new password cannot be the same as the old password",
  "离职申请": "Resignation application",
  "主讲教师": "Lecturer",
  "个人资料": "Personal Data",
  "账号安全": "Account security",
  "密码": "password",
  "修改科目": "Modify the account",
  "更换邮箱": "Change the mailbox",
  "更换密码": "Change the password",
  "原密码": "Original password",
  "请上传jpg,jpeg,png图片格式": "Please upload JPG, JPEG, PNG image format",
  "原密码不正确": "The original password is incorrect"
}
